import React from 'react';
import cn from 'classnames';

import IconCheckCircle from '@dealroadshow/uikit/core/components/Icon/IconCheckCircle';
import baseStyles from '../../tenantInvestorSet.scss';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';

import styles from './features.scss';
import { featuresCollection, featuresDescription, featuresTitle } from './constants';

const featuresCardContent = (feature, index) => (
  <div
    key={ index }
    className={ styles.feature }
  >
    <div className={ styles.featureIcon }>
      <IconCheckCircle />
    </div>
    <div className={ styles.featureTitle }>{ feature }</div>
  </div>
);

const Features = () => {
  const featuresCards = featuresCollection.map((featuresCard, index) => (
    <div
      key={ featuresCard.key }
      className={ cn(styles.featuresColumnWrp, {
        [styles.columnWithShadow]: !!(index % 2),
      }) }
    >
      <div className={ styles.featuresColumn }>
        <div className={ styles.featureColumnHeader }>
          <img
            className={ styles.featureColumnIcon }
            src={ featuresCard.icon }
          />
          <span className={ styles.featureColumnTitle }>{ featuresCard.title }</span>
        </div>
        { featuresCard.collection.map(featuresCardContent) }
      </div>
    </div>
  ));

  return (
    <div className={ styles.featuresWrp }>
      <div className={ styles.features }>
        <div className={ baseStyles.mdContainer }>
          <div className={ baseStyles.sectionTitle }>{ featuresTitle }</div>
          <div className={ baseStyles.sectionDescription }>{ featuresDescription }</div>
        </div>
        <div className={ cardStyles.cardContainer }>
          <div className={ styles.featuresRow }>{ featuresCards }</div>
        </div>
      </div>
    </div>
  );
};

export default Features;
